import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  actionMenuDisplayClass = 'invisible'
  checkedClass = 'checked'

  static targets = [
    'selectAllPagesCheckbox',
    'selectAllPagesLabel',
    'selectPageCheckbox',
    'recordCheckbox',
    'stringifiedParams',
    'actionMenu'
  ]

  handleSelectAllPagesCheckbox() {
    this.toggleCheckbox(this.selectAllPagesCheckbox())
    this.selectAllPagesLabel().toggleClass('selected')

    if (this.selectAllPagesCheckbox().hasClass(this.checkedClass)) {
      this.checkCheckbox(this.selectPageCheckbox())
      this.checkCheckbox(this.recordCheckboxes())
    } else {
      this.uncheckCheckbox(this.selectPageCheckbox())
      this.uncheckCheckbox(this.recordCheckboxes())
    }

    this.toggleActionMenu()
    this.setBulkCheckboxParamsForActionMenuForms(this.selectAllPagesCheckbox())
  }

  handleSelectPageCheckbox() {
    this.toggleCheckbox(this.selectPageCheckbox())

    if (this.selectPageCheckbox().hasClass(this.checkedClass)) {
      this.checkCheckbox(this.recordCheckboxes())
    } else {
      this.uncheckCheckbox(this.recordCheckboxes())
      this.uncheckCheckbox(this.selectAllPagesCheckbox())
      this.selectAllPagesLabel().removeClass('selected')
    }

    this.toggleActionMenu()
    this.setBulkCheckboxParamsForActionMenuForms(this.selectPageCheckbox())
  }

  handleIndividualCheckbox(e) {
    this.toggleCheckbox($(e.currentTarget))

    this.uncheckCheckbox(this.selectPageCheckbox())
    this.uncheckCheckbox(this.selectAllPagesCheckbox())
    this.selectAllPagesLabel().removeClass('selected')

    this.toggleActionMenu()
    this.setIndividualCheckboxParamsForActionMenuForms()
  }

  handleFormSubmit(e) {
    Able.Utils.submitForm(
      $(e.currentTarget).find('form')
    )
  }

  toggleCheckbox(target) {
    $(target).toggleClass(this.checkedClass)
  }

  selectAllPagesCheckbox() {
    return $(this.selectAllPagesCheckboxTarget)
  }

  selectPageCheckbox() {
    return $(this.selectPageCheckboxTarget)
  }

  selectAllPagesLabel() {
    return $(this.selectAllPagesLabelTarget)
  }

  recordCheckboxes() {
    return $(this.recordCheckboxTargets)
  }

  checkCheckbox(target) {
    target.addClass(this.checkedClass)
  }

  uncheckCheckbox(target) {
    target.removeClass(this.checkedClass)
  }

  setBulkCheckboxParamsForActionMenuForms(checkboxSelector) {
    const params = this.paramsFromCheckbox(checkboxSelector)
    this.setStringifiedRansackParams(params)
  }

  setIndividualCheckboxParamsForActionMenuForms() {
    const params = this.allRecordIdsParam() + '&' + this.paramsFromCheckbox(this.selectPageCheckbox())
    this.setStringifiedRansackParams(params)
  }

  paramsFromCheckbox(checkboxSelector) {
    return checkboxSelector.data('params')
  }

  setStringifiedRansackParams(params) {
    const paramsWithPageOne = params.replace('page=&', 'page=1&').replace(/page=$/, 'page=1')
    _.each(this.stringifiedParamsTargetElements(), function (el) {
      el.setAttribute('data-stringified-ransack-params', paramsWithPageOne)
    })

    this.formInputs().val(paramsWithPageOne)
  }

  stringifiedParamsTargetElements() {
    return $(this.stringifiedParamsTargets)
  }

  formInputs() {
    return $('input.js-set-stringified-ransack-params')
  }

  allRecordIdsParam() {
    return $.param({ q: { id_in: this.allRecordIds() } })
  }

  allRecordIds() {
    return $.map(this.checkedRecordCheckboxes(), function (checkbox) {
      return $(checkbox).data('record-id')
    })
  }

  checkedRecordCheckboxes() {
    return this.recordCheckboxes().filter('.' + this.checkedClass)
  }

  toggleActionMenu() {
    if ($('.checkbox').hasClass(this.checkedClass)) {
      $(this.actionMenuTarget).removeClass(this.actionMenuDisplayClass)
    } else {
      $(this.actionMenuTarget).addClass(this.actionMenuDisplayClass)
    }
  }
}
