import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["stageToggle", "finished"]

  connect() {
    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    // Stop polling if the import has finished
    if (this.finishedTarget.dataset.finished == "true") {
      this.stopRefreshing()
      return
    }

    const url = this.buildFullUrl()

    fetch(url, { headers: { "Accept": "application/javascript" } })
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  buildFullUrl() {
    var url = this.data.get("url")

    const openStages = this.stageToggleTargets
      .filter(stageToggle => window.getComputedStyle(stageToggle).display !== "none")
      .map(stageToggle => stageToggle.dataset.id)

    return url + '?' + $.param({ toggled_stage_ids: openStages })
  }
}
