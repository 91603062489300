import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.feedbackForAllInputs()
  }

  feedbackForAllInputs() {
    const _this = this
    $.each($('form input, form select'), function (index, value) {
      const formElement = $(value)
      _this.highlightSelectMenu(formElement, _this.blankIndexForTarget(formElement))
    })
  }

  triggerInputFeedback(event) {
    const formElement = $(event.currentTarget)
    this.highlightSelectMenu(formElement, this.blankIndexForTarget(formElement))
  }

  highlightSelectMenu(menu, blankIndex) {
    if (menu.prop('selectedIndex') === blankIndex) {
      menu.removeClass('highlight-secondary')
    } else {
      menu.addClass('highlight-secondary')
    }
  }

  blankIndexForTarget(target) {
    const firstOption = target.find('option:first')
    return (firstOption.attr('value') == '' ? 0 : null)
  }
}
