import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["rawConfigYaml"]

  initialize() {
    this.setEditor()
    this.loadYamlContents()
  }

  setEditor() {
    ace.config.loadModule('ace/ext/searchbox')

    const editor = ace.edit('editor')
    editor.session.setMode('ace/mode/yaml')
    editor.setOptions({
      mode: 'ace/mode/yaml',
      showInvisibles: true,
      showPrintMargin: false,
      tabSize: 2,
      theme: 'ace/theme/monokai',
      useSoftTabs: true
    })
    this.editor = editor
  }

  loadYamlContents() {
    this.editor.setValue(this.rawConfigYamlTarget.value)
  }

  setRawYaml() {
    this.rawConfigYamlTarget.value = this.editor.getValue()
  }
}
