import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "insertedElement" ]

  connect() {
    const that = this

    $(this.element).on('hidden.bs.modal', (event) => {
      that.cleanUpInsertedElements(event)
    })
  }

  populateModal(event) {
    const currentTarget = event.currentTarget
    const modalContentDiv = $(currentTarget.dataset.target + ' .modal-content')

    $.ajax({
      type: 'get',
      url: currentTarget.dataset.url,
      data: { stringified_ransack_params: currentTarget.dataset.stringifiedRansackParams },
      success: function (response) { modalContentDiv.html(response) }
    })
  }

  reloadPage(event) {
    $(event.currentTarget).closest('.modal').modal('hide')
    location.assign(location.href)
  }

  populateRemoteFormModal(event) {
    const contentPath = $(event.currentTarget).data('url')
    return $.get(contentPath)
  }

  cleanUpInsertedElements(_e) {
    $.each(this.insertedElementTargets, function(_i, element) {
      $(element).remove();
    })
  }
}
