import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  initialize() {
    this.initializeInputs()
  }

  initializeInputs() {
    const inputs = $(this.inputTargets)

    _.each(inputs, function (input) {
      var multiple = input.getAttribute('data-multiple') === 'true'

      $(input).select2({
        ajax: {
          delay: 150,
          url: input.dataset.searchendpoint,
          data: function (params) {
            var query = {
              q: params.term,
              page: params.page || 1
            }

            return query;
          }
        },

        minimumInputLength: 3,
        multiple: multiple,
        width: '100%'
      });
    })
  }
}
