import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { targetId: String, url: String }
  rowAction = 'click->row-modal#populateModal'

  connect() {
    const that = this

    $('tr td:not(:first-child)').each(function() {
      $(this).attr('data-action', that.rowAction)
    })
  }

  populateModal() {
    $(this.targetIdValue).modal('show')
    $.get(this.urlValue)
  }
}
