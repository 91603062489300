import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['warning', 'continueBtn', 'checkbox']
  static values = { requiredCount: Number }

  connect() {
    if (this.checkedCheckboxesCount() < this.requiredCountValue) {
      this.showWarning()
    } else {
      this.enableSubmit()
    }
  }

  checkedCheckboxesCount() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked).length
  }

  checkedCount(currentTarget) {
    // This function is triggered when the user checks/unchecks the box but BEFORE the checkbox changes, so we account
    // for that with countDelta
    const isTargetCheckboxChecked = currentTarget.querySelector('input[type="checkbox"]').checked
    const countDelta = isTargetCheckboxChecked ? -1 : 1

    return this.checkedCheckboxesCount() + countDelta
  }

  count({currentTarget}) {
    // this function is triggered before the .checked class is added to the currentTarget, so we need to account for that here
    if (this.checkedCount(currentTarget) < this.requiredCountValue) {
      this.showWarning()
    } else {
      this.enableSubmit()
    }
  }

  enableSubmit() {
    this.warningTarget.hidden = true
    this.continueBtnTarget.disabled = false
  }

  showWarning() {
    this.warningTarget.hidden = false
    this.continueBtnTarget.disabled = true
  }
}
