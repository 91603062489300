import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "row", "conditionalRequirerCheckbox", "conditionallyRequired", "select" ]

  addRow(event) {
    const lastRow = $(this.rowTargets).last()

    if (lastRow.is(':visible')) {
      const newRow = lastRow.clone()

      newRow.find('input').val('')
      newRow.insertAfter(lastRow)
    } else {
      lastRow.show()
    }
  }

  deleteRow(event) {
    if (this.rowTargets.length === 1) {
      $(event.currentTarget).closest('tr').hide()
    } else {
      $(event.currentTarget).closest('tr').remove()
    }
  }

  removeBlankRows(event) {
    const that = this
    const rows = $(this.rowTargets)

    $.each(rows, function (index, row) {
      const relevantInputs = $(row).find('input')
      const allEmpty = _.all(relevantInputs, function (relevantInput) { return that.inputIsBlank(relevantInput) })
      if (allEmpty && index !== 0) { $(row).remove() }
    })
  }

  inputIsBlank(inputElement) {
    return $.trim($(inputElement).val()) === ''
  }

  populateSelect(event) {
    const select = this.selectTarget
    const shouldPopulate = !select.disabled

    if (shouldPopulate) {
      const [endpoint, suppliedQueryParams] = this.selectTarget.getAttribute('data-options-endpoint').split('?')
      const params = new URLSearchParams(suppliedQueryParams)

      params.append('search_query', event.target.value)
      params.append('selected', select.value)

      const endpointWithQuery = `${endpoint}?${params}`

      Rails.ajax({
        type: 'get',
        url: endpointWithQuery,
        success: function(response) {
          select.innerHTML = response.body.innerHTML
        }
      })
    }
  }

  toggleRequired(event) {
    event.stopPropagation()
    event.preventDefault()

    if (this.conditionalRequirerCheckboxTarget.checked) {
      this.conditionallyRequiredTarget.setAttribute('required', '');
    } else {
      this.conditionallyRequiredTarget.removeAttribute('required');
    }
  }
}
