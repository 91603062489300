import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["entityCount"];
    static values = {
        manualEntitySelectionMethod: String,
        automaticEntitySelectionMethod: String
      }

    connect() {
        this.entityCountTarget.style.display = 'none';
    }

    change(event) {
        if (!this.entityCountTarget) return;

        if (event.target.value === this.manualEntitySelectionMethodValue) {
            this.entityCountTarget.style.display = 'block';
        } else if(event.target.value === this.automaticEntitySelectionMethodValue) {
            this.entityCountTarget.style.display = 'none';
        }
    }
}