import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    activePath: String,
    timeoutUrl: String,
    start: Number,
    frequency: Number
  }

  connect() {
    this.schedulePing(this.startValue)
    this.lastKeepAliveTime = Date.now()
  }

  csrfToken() {
    const csrfEl = document.querySelector('meta[name="csrf-token"]');

    let csrfToken = '';
    if (csrfEl) {
      csrfToken = csrfEl.content;
    }

    return csrfToken
  }

  forceRedirect(redirectURL) {
    window.onbeforeunload = null;
    window.onunload = null;
    window.location.href = redirectURL;
  }

  keepalive(event) {
    if (this.keepAliveRateLimited()) {
      return
    }

    const request = new XMLHttpRequest();
    request.open('POST', '/sessions/keepalive', true);
    request.setRequestHeader('X-CSRF-Token', this.csrfToken());

    const _this = this;
    request.onload = function () {
      _this.processResponse(JSON.parse(request.responseText))
    };

    request.send();
  }

  keepAliveRateLimited() {
    const currentTime = Date.now()

    const millis = currentTime - this.lastKeepAliveTime
    const seconds = Math.floor((millis) / 1000)
    if (seconds >= this.frequencyValue) {
      this.lastKeepAliveTime = currentTime
      return false
    }

    return true
  }

  ping() {
    const request = new XMLHttpRequest();
    request.open('GET', this.activePathValue, true);

    const _this = this;
    request.onload = function () {
      _this.processResponse(JSON.parse(request.responseText));
    };

    request.send();
    this.schedulePing(this.frequencyValue)
  }

  schedulePing(delay) {
    setTimeout(() => this.ping(), delay * 1000);
  }

  processResponse(responseData) {
    const timeRemaining = responseData.remaining * 1000;

    if (!responseData.live) {
      if (this.timeoutUrlValue) {
        this.forceRedirect(this.timeoutUrlValue)
      }
      return;
    }

    if (timeRemaining < this.frequencyValue) {
      timeRemaining = timeRemaining < 0 ? 0 : timeRemaining
      this.schedulePing(timeRemaining)
    }

    this.element.dataset.sessionTimeRemaining = timeRemaining
  }
}
