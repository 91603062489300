import { Controller } from "stimulus"

export default class extends Controller {
  openAccordion(event) {
    $(event.currentTarget).closest('.accordion').find('.accordion-body').slideToggle()

    var toggleIcons = event.currentTarget.querySelectorAll('.accordion-arrow-icons span')
    toggleIcons.forEach((toggleIcon) => {
      toggleIcon.classList.toggle('hide')
    });
  }
}
