import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  handleFileSelection(event) {
    this._updateFileInputLabel(event)
    this._displaySelectedFilenames(event)
  }

  _updateFileInputLabel(event) {
    const fileInput = event.currentTarget
    const files = fileInput.files
    let fileName = ''
    const label = $(fileInput).siblings('label')
    const labelVal = label.html()

    if (files && files.length > 1) {
      fileName = (fileInput.getAttribute('data-multiple-caption') || '').replace('{count}', files.length)
    } else {
      fileName = files[0].name
    }

    if (fileName) {
      label.children('span').html(fileName)
    } else {
      label.html(labelVal)
    }
  }

  _displaySelectedFilenames(event) {
    const list = $(event.currentTarget).siblings('.file_list')
    const files = event.currentTarget.files

    list.html('')

    if (files.length > 1) {
      for (let i = 0; i < files.length; i++) {
        list.append(files[i].name + '\n')
      }
    }

    if (_.isEmpty(list.html())) {
      list.hide()
    } else {
      list.show()
    }
  }

  reloadPage(event) {
    event.stopPropagation()
    $(event.currentTarget).closest('.modal').modal('hide')
    const currentUrl = window.location.href
    Able.Utils.replaceWindowLocation(currentUrl)
  }

  handleFileDeletion(event) {
    event.stopPropagation()
    const validationCaseId = $(event.currentTarget).data('validationCaseId')
    $.get('/validations/' + validationCaseId + '/edit')
  }
}
