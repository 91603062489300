import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "searchScopeInput", "searchInputContainer" ]

  initialize() {
    const programMemberGid = this.searchInputContainerTarget.getAttribute('data-program-member-gid')
    this.populateSearchInput(programMemberGid)
  }

  handleScopeChange() {
    this.populateSearchInput()
  }

  populateSearchInput(programMemberGid = null) {
    const searchScope = $(this.searchScopeInputTarget).val()

    if (searchScope) {
      const url = '/patients/measure_results_filter' + '?' + $.param({ member_type: searchScope, program_member_gid: programMemberGid})

      fetch(url, { headers: { "Accept": "application/javascript" } })
        .then(response => response.text())
        .then(html => this.searchInputContainerTarget.innerHTML = html)
    } else {
      this.searchInputContainerTarget.innerHTML = ''
    }
  }
}
