import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card"]

  connect() {
    if(this.data.get("display-leaderboard-content") == 'true') {
      this.loadInsights()
    }
  }

  loadInsights() {
    const url = this.buildFullUrl()
    const cardTarget = this.cardTarget

    $.ajax({
      type: 'get',
      url: url,
      success: function (response) { cardTarget.innerHTML = response }
    })
  }

  buildFullUrl() {
    var url = this.data.get("url")
    return url
  }
}
