import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['replaceableContent']

  submitOptIn(event) {
    event.preventDefault();
    const replaceableContent = this.replaceableContentTarget

    Rails.ajax({
      type: "put",
      url: event.currentTarget.dataset.url,
      success: function(response) { replaceableContent.innerHTML = response.body.innerHTML },
    })
  }
}
