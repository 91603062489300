import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('validation_case_id')) {
      const validationCaseId = urlParams.get('validation_case_id')

      $('#validation-case-modal').modal('show');
      $.get('/validation_cases/' + validationCaseId + '/edit')
    }
  }
}
