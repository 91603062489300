import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['text', 'fieldName']

  copyText() {
    const textToCopy = this.textTarget.innerText

    const el = document.createElement('textarea')
    el.value = textToCopy
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    this.flashConfirmation()
  }

  flashConfirmation() {
    const copiedFieldName = this.fieldNameTarget.innerText
    const flashMessage =
      '<div class="alert alert-info alert-dismissable flash_message">' +
      copiedFieldName + ' copied to clipboard.' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>'

    $('.flash_messages').append(flashMessage)
    setTimeout(() => { $('.flash_message').fadeOut(500) }, 3000)
  }
}
