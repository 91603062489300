import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  submitFormFromCheckbox(_e) {
    $(this.formTarget).submit()
  }

  submitRemoteFormFromCheckbox(_e) {
    const url = $(this.formTarget).attr('action')
    const form = this.formTarget

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'html',
      data: $(form).serialize(),
      success: function(response) {
        $(form).replaceWith(response)
      }
    });
  }
}
