import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['notifyCheckbox', 'spinner']

  toggleNotify() {
    this.spinnerTarget.className = "d-inline"
    this.notifyCheckboxTarget.className = "d-none"
    if(this.notifyCheckboxTarget.checked) {
      this.createNotification()
    } else {
      const notificationId = this.data.get("id")
      if(notificationId) {
        this.deleteNotification(notificationId)
      }
    }
  }

  createNotification() {
    const notificationCreatePath = this.data.get("createPath")
    const controller = this
    let controllerData = controller.data
    Rails.ajax({
      type: "post",
      url: notificationCreatePath,
      success: function(response) {
        const newNotificationId = response.id
        controllerData.set("id", newNotificationId)
        controller.hideSpinner()
      },
    })
  }

  deleteNotification(notificationId) {
    const path = "/able_admin/notifications/"
    const notificationDeletePath = path + notificationId
    const controller = this
    Rails.ajax({
      type: "delete",
      url: notificationDeletePath,
      success: function() {
        controller.hideSpinner()
      }
    })
  }

  hideSpinner() {
    this.spinnerTarget.className = "d-none"
    this.notifyCheckboxTarget.className = ""
  }
}
