import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['periodType', 'rollingIntervalPeriod', 'fixedPeriod']
  static values =  { fixedPeriod: String }

  initialize() {
    this.displayPeriodFields()
  }

  showFixedPeriodFields() {
    this.fixedPeriodTargets.forEach((target) => {
      target.classList.remove("d-none")
    })
  }

  hideFixedPeriodFields() {
    this.fixedPeriodTargets.forEach((target) => {
      target.classList.add("d-none")
    })
  }

  showRollingPeriodFields() {
    this.rollingIntervalPeriodTarget.classList.remove("d-none")
  }

  hideRollingPeriodFields() {
    this.rollingIntervalPeriodTarget.classList.add("d-none")
  }

  displayPeriodFields() {
    if(this.periodTypeTarget.value == this.fixedPeriodValue) {
      this.showFixedPeriodFields()
      this.hideRollingPeriodFields()
    } else {
      this.showRollingPeriodFields()
      this.hideFixedPeriodFields()
    }
  }
}
