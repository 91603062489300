import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dashboardSubdomain", "fullImportModelCheckboxContainer"]

  retrieveFullImportModels() {
    const dashboardSubdomain = this.dashboardSubdomainTarget.value
    const path = this.data.get("path")

    const fullPath = path + '?' + $.param({dashboard_subdomain: dashboardSubdomain})

    if (dashboardSubdomain != "") {
      fetch(fullPath, { headers: { "Accept":"application/javascript" } })
        .then(response => response.text())
        .then(html => this.fullImportModelCheckboxContainerTarget.innerHTML = html)
    }else{
      this.fullImportModelCheckboxContainerTarget.innerHTML = ""
    }
  }
}
