import { Controller } from "stimulus"

export default class extends Controller {
  openShow(event) {
    const showPath = event.currentTarget.dataset.showPath

    if (event.ctrlKey || event.metaKey) {
      // ctrlKey to detect ctrl + click
      // metaKey to detect command + click on MacOS
      return window.open(showPath, '_blank')
    } else {
      window.location.assign(showPath)
    }
  }
}