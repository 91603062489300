import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["altSubmitBtn", "customerSelect", "selectedCustomerContainer", "submitBtn"]
  static values = {
    initialCustomer: String
  }

  confirmCustomerSelection(_event) {
    const selectedCustomer = this.selectedCustomer();

    if (this.isCustomerSelected()) {
      if (selectedCustomer !== this.initialCustomerValue) {
        this.populateModal(selectedCustomer)
        this.requireConfirmation()
      } else {
        this.disableConfirmation()
      }
    } else {
      this.disableConfirmation()
    }
  }

  selectedCustomer() {
    return $('option:selected', this.customerSelectTarget).text()
  }

  isCustomerSelected() {
    return this.selectedCustomer().length !== 0
  }

  populateModal(selectedCustomer) {
    this.selectedCustomerContainerTarget.innerHTML = selectedCustomer
  }

  requireConfirmation() {
    this.submitBtnTarget.hidden = true
    this.altSubmitBtnTarget.hidden = false
  }

  disableConfirmation() {
    this.submitBtnTarget.hidden = false
    this.altSubmitBtnTarget.hidden = true
  }
}
