import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  checkedClass = 'checked'

  static targets = [ "displayCheckbox" ]

  connect() {
    const that = this
    const displayCheckboxes = $(this.displayCheckboxTargets)

    $.each(displayCheckboxes, function (index, value) {
      let checkbox = $(value)

      if (checkbox.find('input:checkbox').attr('checked') && !checkbox.hasClass('checked')) {
        that.toggleCheckbox(checkbox)
      }
    })
  }

  toggleCheckboxFromClick(event) {
    event.stopPropagation()
    event.preventDefault()

    const formCheck = $(event.currentTarget)
    const checkbox = formCheck.find('.checkbox')
    const checkboxInput = checkbox.find('input:checkbox')

    this.toggleCheckbox(checkbox)
    checkboxInput.prop('checked', !checkboxInput.prop('checked'))
  }

  toggleCheckbox (checkbox) {
    checkbox.toggleClass(this.checkedClass)
  }
}
