import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['currentScore', 'modalContent', 'reloadableContent']

  replaceButton(event) {
    $(event.currentTarget).parent().html(event.detail[0].body.innerHTML)
  }

  replaceContent(event) {
    $(event.currentTarget.closest('.measure-wrapper')).replaceWith(event.detail[0].body.innerHTML)
  }

  reloadContent() {
    let targetElement = $(this.reloadableContentTarget)
    const targetId = targetElement.attr('id')
    const loadUrl = window.location.href + " #" + targetId
    targetElement.load(loadUrl)
  }

  reloadPage() {
    const currentUrl = window.location.href
    Able.Utils.replaceWindowLocation(currentUrl)
  }

  showErrorInModal(event) {
    $(this.modalContentTarget).replaceWith(event.detail[0].body.innerHTML)
  }

  updateCurrentScore(event) {
    const currentScore = event.detail.currentScore
    const currentCategoryScore = event.detail.currentCategoryScore

    $(this.currentScoreTarget).text(currentScore)
    $('#estimated-points-card .current-category-score').text(currentCategoryScore)
  }
}
