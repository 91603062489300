import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const url = this.data.get('path')

    Rails.ajax({
      type: 'get',
      url: url,
      success: function (response) { $('.pagy-count').html(response.body.innerHTML) }
    })
  }
}
